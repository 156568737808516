<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <div class="button-not-print"></div>
    <div class="main">
      <!-- แบ่งครึง panel ส่วนซ้าย -->
      <div class="left">
        <!-- ปริ้นโดย 10 cm -->
        <div v-if="printType == 1">
          <div
            v-for="(orderItem, index) in orderItemList"
            :key="index"
            class="justify-content-center"
             
          > 
            <v-card :elevation="0" class="pages  pa-3 ">
               
              <template v-if="orderItem.pages == 1">
              <v-row class="text-center  cen mt-3">
                <v-col cols="4"  >
                  <img cover :src="orderItem.expressCompanyLogo" width="100%" />
                </v-col>
                <v-col cols="4" class="pa-0" >
                  
                  <!-- <img  v-if="orderItem.warehouseLogo != null" :src="require('../../../assets/Logo_Packhai_01.png')" width="100%"     /> -->
                <img cover :src="orderItem.warehouseLogo" width="100%" />
                </v-col>
                <v-col cols="4"    >                 
                    <b v-if="orderItem.codAmount != null"> <h1  style="border: 2px solid;">COD</h1></b>  
                     
                </v-col>
              </v-row>
              <v-row class="text-center ma-0" align="baseline"> 
                <v-col cols=" ">
                  

                  <template v-if="orderItem.expressCompanyID == 0">
                    <barcode
                    v-bind:value="orderItem.orderMasterID"
                    :text="'PA'+orderItem.orderMasterID"
                    height="50"
                    width="2"
                    fontSize="18"
                    marginTop="1"
                    marginBottom="1"
                    textAlign="center"
                  />
                  </template>
                  <template v-else>
                    <barcode
                    v-bind:value="orderItem.trackingNo"
                    :text="orderItem.trackingNo"
                    height="50"
                    width="2"
                    fontSize="18"
                    marginTop="1"
                    marginBottom="1"
                    textAlign="center"
                  />
                  </template>


                  
                </v-col>
                <v-col  cols="auto"   >
                  <v-row  class="justify-content-center">
                  <template v-if="orderItem.expressCompanyID == 0">
                    <vue-qrcode
                  :scale="3"
                  :value="orderItem.orderMasterID.toString()"/>
                  </template>
                  <template v-else>
                    <vue-qrcode
                  :scale="3"
                  :value="orderItem.trackingNo"/>
                  </template>
                   
                  
                  </v-row>
                  <v-row>
                    <v-col  cols="12"  class="box text-center pa-0 ma-0" v-if="orderItem.sortCode5 != null">
                    <b class="fon2mm  " >{{orderItem.sortCode5}}</b>
                  </v-col>
                  </v-row>
                 
                  
                </v-col>
              </v-row>
              
              <v-row class="pt-2 ma-0 justify-content-center "   >
                <v-col v-if="orderItem.sortCode1 != null" :cols="autosetCols(orderItem)" class="box2 text-center" >
                  <b  class="fon2mm">{{orderItem.sortCode1}}</b>
                </v-col>
                <v-col v-if="orderItem.sortCode2 != null"  :cols="autosetCols(orderItem)" class="box2 text-center">
                  <b  class="fon2mm">{{orderItem.sortCode2}}</b>
                </v-col>
                <v-col  v-if="orderItem.sortCode3 != null"  :cols="autosetCols(orderItem)" class="box2 text-center">
                  <b  class="fon2mm">{{orderItem.sortCode3}}</b>
                </v-col>
                <v-col v-if="orderItem.sortCode4 != null" :cols="autosetCols(orderItem)" class="box2 text-center">
                  <b  class="fon2mm">{{orderItem.sortCode4}}</b>
                </v-col>
              </v-row>
          
              <v-divider />
              <!-- ผู้ส่ง -->
              <v-row class="py-2  ma-0 justify-content-center " >
                    <v-col cols="12" class="box-none">
                      <v-row>
                  <v-col cols="12" class="fon1mm"><b>ผู้ส่ง</b></v-col>
                </v-row>              
                  <v-row>
                  <v-col cols="12" class="fon1mm"><b>ชื่อ : </b> {{orderItem.senderAddress.name}}</v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" class="fon1mm"><b>เบอร์โทร : </b> {{orderItem.senderAddress.phone}}</v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" class="fon1mm"><b>ที่อยู่ : </b> {{orderItem.senderAddress.address}}   {{orderItem.senderAddress.subDistrict}}  {{orderItem.senderAddress.district}}
                     {{orderItem.senderAddress.province}}   {{orderItem.senderAddress.postcode}} </v-col>
                </v-row>

                    </v-col>
              </v-row>
              <!-- ผู้รับ -->
              <v-row class="  ma-0 justify-content-center " >
                <v-col cols="12" class="box">
                  <v-row >
              <v-col cols="12" class="fon2mm"><b>ผู้รับ</b></v-col>
            </v-row>              
              <v-row>
              <v-col cols="12" class="fon2mm"><b>ชื่อ : </b> {{orderItem.receiverAddress.name}}</v-col>
            </v-row>
              <v-row>
              <v-col cols="12" class="fon2mm"><b>เบอร์โทร : </b> {{orderItem.receiverAddress.phone}}</v-col>
            </v-row>
              <v-row>
              <v-col cols="12" class="fon2mm"><b>ที่อยู่ : </b> {{orderItem.receiverAddress.address}}   {{orderItem.receiverAddress.subDistrict}}  {{orderItem.receiverAddress.district}}
                  {{orderItem.receiverAddress.province}}   {{orderItem.receiverAddress.postcode}} </v-col>
            </v-row>
                </v-col>
              </v-row>  
              </template>

              <!-- ส่วนออเดอร์ -->
                  <v-row class="  ma-0 justify-content-center " >
                  <v-col cols="12" class="box-none">
                    <v-row  >
                      <v-col cols="12" class="fon2mm "><b>Order PA{{ orderItem.orderMasterID}} , ปริ้นวันที่ {{ DateNow() }}</b></v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div v-if="!hideOrder">
              <v-row class="  ma-0 justify-content-center " >
                  <v-col cols="12" class="box">
                  <v-row>
                    <v-col cols="2" class="fon1mm ">
                      <span><b>#</b></span> 
                    </v-col>
                    <v-col cols="7" class="fon1mm">
                      <span><b>สินค้า</b></span> 
                    </v-col>
                    <v-col cols="3" class="fon1mm">
                      <span><b>จำนวน(หน่วย)</b></span> 
                    </v-col>
                 </v-row>
                 </v-col>
              </v-row>
          <v-row class="  ma-0 justify-content-center " >
            <v-col cols="12" class="box-none">   
              <div
            v-for="(item, index) in orderItem.productList"
            :key="index"
          >
            <v-row>
              <v-col cols="2" class="fon1mm ">
                <span>
                  {{ index+1 }}</span
                >
              </v-col>
              <v-col cols="7" class="fon1mm">
                <span>
                  {{ item.name }}
                </span>
              </v-col>

              <v-col cols="3" class="fon1mm">
                <span> {{ item.quantity }}</span>
              </v-col>
            </v-row>
          </div>

            </v-col>
          </v-row>                
              </div>     
            </v-card>
          </div>
        </div>
        <div v-else-if="printType == 2" >
          <div v-for="(orderItem, index) in orderItemList" :key="index" class="justify-content-center "  >
            <v-card :elevation="0" class="pageA4 pa-3" >
              <table class="text-center mt-2">
                <tr>
                  <th>
                    <div>
                      <img class="mx-2"   :src="orderItem.expressCompanyLogo" width="35%" />
                      <!-- <img class="mx-2"  v-if="orderItem.warehouseLogo != null" :src="require('../../../assets/Logo_Packhai_01.png')" width="35%" /> -->
                      <img class="mx-2"   :src="orderItem.warehouseLogo" width="35%" />

                    </div>
                    <div>
                      <template v-if="orderItem.expressCompanyID == 0">
                      <barcode
                      v-bind:value="orderItem.orderMasterID"
                      :text="'PA'+orderItem.orderMasterID"
                      height="40"
                      width="2"
                      fontSize="18"
                      marginTop="7"
                      marginBottom="5"
                     />
                    </template>
                    <template v-else>
                      <barcode
                      v-bind:value="orderItem.trackingNo"
                      :text="orderItem.trackingNo"
                      height="40"
                      width="2"
                      fontSize="18"
                      marginTop="7"
                      marginBottom="5"
                     />
                    </template> 
                    </div>
                  </th>     
                  <th>  
                    
                    <b v-if="orderItem.codAmount != null"> <h1 style="border: 2px solid;">COD</h1></b>  
                        
                      
                    <template v-if="orderItem.expressCompanyID == 0">
                    <vue-qrcode
                    :scale="5"
                    :value="orderItem.orderMasterID.toString()"/>
                    </template>
                    <template v-else>
                    <vue-qrcode
                    :scale="5"
                    :value="orderItem.trackingNo"/>
                    </template>


                  
                  <v-row class=" justify-content-center mb-2 ma-0 pa-0"   >

                  
                  <v-col v-if="orderItem.sortCode5 != null"  cols="12" class="box text-center ma-0 pa-0" >
                  <b   >{{orderItem.sortCode5}}</b>
                </v-col>
              </v-row>
                  </th>
                </tr>
              </table>
              <div >
                <v-row class=" justify-content-center mb-2 ma-0 pa-0"   >
                <v-col  v-if="orderItem.sortCode1 != null" :cols="autosetCols(orderItem)" class="box text-center ma-0 pa-0" >
                  <b   >{{orderItem.sortCode1}}</b>
                </v-col>
                <v-col  v-if="orderItem.sortCode2 != null" :cols="autosetCols(orderItem)" class="box text-center ma-0 pa-0">
                  <b  >{{orderItem.sortCode2}}</b>
                </v-col>
                <v-col  v-if="orderItem.sortCode3 != null" :cols="autosetCols(orderItem)" class="box text-center ma-0 pa-0">
                  <b  >{{orderItem.sortCode3}}</b>
                </v-col>
                <v-col  v-if="orderItem.sortCode4 != null" :cols="autosetCols(orderItem)" class="box text-center ma-0 pa-0">
                  <b  >{{orderItem.sortCode4}}</b>
                </v-col>
                
                
              </v-row>
              </div>
              <v-row>
                <!-- ผู้ส่ง -->
                <v-col cols="6">
                  <div class="box-none pa-1">
                  <v-row>
                  <v-col cols="12" ><b>ผู้ส่ง</b></v-col>
                </v-row>              
                  <v-row>
                  <v-col cols="12" ><b>ชื่อ : </b> {{orderItem.senderAddress.name}}</v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" ><b>เบอร์โทร : </b> {{orderItem.senderAddress.phone}}</v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" ><b>ที่อยู่ : </b> {{orderItem.senderAddress.address}}   {{orderItem.senderAddress.subDistrict}}  {{orderItem.senderAddress.district}}
                     {{orderItem.senderAddress.province}}   {{orderItem.senderAddress.postcode}} </v-col>
                </v-row>
                </div>
                </v-col>
                 <!-- ผู้รับ -->
                <v-col cols="6">
                  <div class="box2 pa-1">
                  <v-row >
                  <v-col cols="12" ><h3><b>ผู้รับ</b></h3></v-col>
                </v-row>              
                  <v-row>
                  <v-col cols="12" >  <h3><b>ชื่อ : </b> {{orderItem.receiverAddress.name}}</h3> </v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" > <h3><b>เบอร์โทร : </b> {{orderItem.receiverAddress.phone}}</h3></v-col>
                </v-row>
                  <v-row>
                  <v-col cols="12" > <h3><b>ที่อยู่ : </b> {{orderItem.receiverAddress.address}}   {{orderItem.receiverAddress.subDistrict}}  {{orderItem.receiverAddress.district}}
                     {{orderItem.receiverAddress.province}}   {{orderItem.receiverAddress.postcode}} </h3></v-col>
                </v-row>
                </div>
                </v-col>
              </v-row>
              <v-row >
                <div class="box-none pa-1">
                  <v-col cols="12" class="    "><b> Order PA{{ orderItem.orderMasterID}} , ปริ้นวันที่ {{ DateNow() }}</b></v-col>
                </div>
                     
                     
                    </v-row>

                 <!-- ส่วนออเดอร์ -->
                 <div v-if="!hideOrder">
                  <v-row class="pa-1">
                <v-col cols="12" class=" text-center"><b>รายการสินค้า </b></v-col>
                </v-row>
                <v-row >
                  <v-col cols="5" >เลขที่คำสั่งซื้อ : PA{{ orderItem.orderMasterID}}</v-col>
                  <v-col cols="7" class="text-right">จำนวนสินค้า : {{ orderItem.total }} รายการ (รวม  {{ orderItem.totalQuantity }} หน่วย)</v-col>
                </v-row>  
           
              <table>
                <tr>
                  <th>#</th>
                  <th>สินค้า</th>
                  <th>จำนวน(หน่วย)</th>
                 
                </tr>
                <tr v-for="(item, index) in orderItem.productList" :key="index">
                  <td style="text-align: center">{{ index + 1 }}</td>

                  <td style="width: 300px; padding: 0px 0px 0px 10px">
                    {{ item.name }}
                  </td>
                  <td style="text-align: center">{{ item.quantity }}</td>
                </tr>
              </table>
                </div>   
            </v-card>
          </div>
        </div>
      </div>
      <!-- แบ่งครึง panel ส่วนขวา -->
      <div class="right button-not-print">
        <div class="scroll-container">
          <v-row>
            <v-col> </v-col>
          </v-row>
          <v-row class="btn-chip-group">
            <v-col cols="4">
              <span> ขนาดกระดาษ :</span>
            </v-col>
            <v-col cols="8" class="">
              <v-radio-group v-model="printType" row>
                <v-radio
                  :label="items.name"
                  v-for="items in viewTypePrint"
                  :key="items.id"
                  :value="items.id"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="checkbox">
            <v-col cols="4">
              <span> การจัดเรียง : </span>
            </v-col>
            <v-col cols="8">
              <v-checkbox
                hide-details
                v-model="orderByType"
                label="พิมพ์ตามจำนวนสินค้าน้อยไปมาก"
                @click="btnOrderByType(orderByType)"
              
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="checkbox1">
            <v-col cols="4">
              <span>ข้อมูลสินค้า : </span>
            </v-col>
            <v-col cols="8">
              <v-checkbox
                hide-details
                v-model="hideOrder"
                label="ซ่อนข้อมูลสินค้า"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="btn-print">
            <v-col cols="10">
              <v-btn
                :elevation="1"
                color="success"
                dark
                block
                outlined
                x-large
                @click="btnPrint()"
              >
                <v-icon size="25" class="pr-1">fa-solid fa-print</v-icon>
                พิมพ์
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="content-container">
          <!-- content goes here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import VueQrcode from 'vue-qrcode'
import axios from "axios";
import Swal from "sweetalert2"; 
import Loading from "@/website/components/Loading";
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
  logisticService_dotnet
} from "@/website/global";

export default {
  components: {
    barcode: VueBarcode,
    Loading,
    VueQrcode
  },

  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    hideOrder:true,
    logo: "@/assets/C_logopackhai.png",
    data:[],
    packageIDList: [],
    page_loading: true,
    loading: false,

    viewTypePrint: [
      { id: 1, name: "10 cm" },
      { id: 2, name: "A4" },
    ],

    printType: 1,
    orderItemList: [],
    orderItemListOld:null,

    
    item: [],
    Branch_StaffName: "",
    orderByType: false,
  }),
  computed: {
  },
  async created() {
    var branchId = localStorage.getItem("Branch_BranchID");
    await this.getLogo(branchId);

    let key_  = await this.$route.query.key
    var key = sessionStorage.getItem("encodedQueryKey_printAws");
    console.log(key, "key_",key_)
    if(key_ == key ){
      this.data = await JSON.parse(localStorage.getItem('printAws'));
      this.loadDataSeparately();

    }
 
    this.page_loading = false;
  },
  methods: {

     //ส่วนของ ฟังก์ชั่นเช็คตรวจสอบ
    async loadDataSeparately() {
     
      for (const item of this.data) {

        

        if (item != null) {
          item.totalQuantity =  item.productList.reduce((total, item) => total + item.quantity, 0);
          item.total = item.productList.length;
          item.pages = 1
          item.totapages = 1
          this.orderItemList.push(item);
        }
        // if(item.productList.length > 12){
        //   let newItem = {...item}
        //   console.log(item.productList)
        //   newItem.productList  =  newItem.productList.splice(12, newItem.productList.length);
           
        //    newItem.pages = 2
        //    newItem.totapages = 2
        //   this.orderItemList.push(newItem);
        // }
      }
    },
   
    async orderBy() {
      this.loading = true;
      var cloneOBj = [...this.orderItemList];
      
      
      
      var orderNew = cloneOBj.sort((a, b) => {
          return a.totalQuantity > b.totalQuantity  ?- 1 : 1
        
      });

      this.orderItemList = orderNew;
      this.loading = false;
    },
    DateNow() {
      var today = new Date();
      var date =
        (today.getDate().toString().length > 1
          ? today.getDate().toString()
          : "0" + today.getDate().toString()) +
        "-" +
        ((today.getMonth() + 1).toString().length > 1
          ? (today.getMonth() + 1).toString()
          : "0" + (today.getMonth() + 1).toString()) +
        "-" +
        today.getFullYear();
      var h =
        today.getHours().toString().length > 1
          ? today.getHours().toString()
          : "0" + today.getHours().toString();
      var m =
        today.getMinutes().toString().length > 1
          ? today.getMinutes().toString()
          : "0" + today.getMinutes().toString();
      var s =
        today.getSeconds().toString().length > 1
          ? today.getSeconds().toString()
          : "0" + today.getSeconds().toString();
      var time = h + ":" + m + ":" + s;
      var newDatetime = date + " " + time;
      return newDatetime;
    },
  
    autosetCols(i){
       
      console.log("orderItem",i.sortCode1)
      if( i.sortCode2 == null && i.sortCode3 == null && i.sortCode4 == null && i.sortCode5 == null  )
      {
        return "12"
      }
      if( i.sortCode1 != null && i.sortCode2 != null &&i.sortCode3 == null  &&i.sortCode4 == null  )
      {
        return "6"
      }
      if( i.sortCode1 != null && i.sortCode2 != null &&i.sortCode3 != null  &&i.sortCode4 == null  )
      {
        return "4"
      }
      if( i.sortCode1 != null && i.sortCode2 != null &&i.sortCode3 != null  &&i.sortCode4 != null  )
      {
        return "3"
      }


      return "3"

    },
    //ส่วนของ API ยิง
    async getLogo(branchId) {
      await axios
        .post(
          branchService_dotnet + "Branch/get-branch-single",
          {
            branchId: branchId,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          this.logo = res.data.branch;
          // console.log(res,"Logo")
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  
  
    //ส่วนของ btn กดแล้วทำงาน
    btnOrderByType(type){
      if(this.orderItemListOld == null){
        this.orderItemListOld =  [...this.orderItemList];
      }
      if(type){
        this.orderBy();
      }else{
        this.orderItemList = this.orderItemListOld;
      }
    },

    async btnPrint() {
      await print();
    },
  },
};
</script>

<style scoped>
>>> .vue-barcode-element {
  width: 100% !important;
} 

.box {
  /* padding: 3px 10px 3px 15px !important; */
  /* margin: 0px 0px 0px 5px !important; */

  /* height: 100%; */
  border: 1px solid;
  width: 95%;
  /* border-radius: 5px; */
}
.box2 {
  /* padding: 3px 10px 3px 15px !important;
  margin: 0px 0px 0px 5px !important; */

  /* height: 100%; */
  border: 1px solid;
  width: 99%;
  /* border-radius: 5px; */
}
.box-none {
  padding: 3px 10px 3px 15px !important; 
  margin: 0px 0px 0px 5px !important;
  /* height: 100%; */
  border: 1px none;
  width: 95%;
  border-radius: 5px;
}

.justify-content-center{
  display: flex; 
  justify-content: center;
}
.fon2mm {
  font-size: 4mm;
  color: #000;
  line-height: 4.5mm;
}
.fon1mm {
  font-size: 2.5mm;
  color: #000;
  line-height: 2.4mm;
}

.fon1-5mm {
  font-size: 3.5mm;
  color: #000;
  line-height: 2.4mm;
}


.cen {
  display: flex;
  align-items: center;
}

 

@media print {
  .button-not-print {
    display: none;
  }
  .pages {
    height: 10cm landscape;
    width: 10cm;
    margin: 0;
    page-break-after: always;
  }
  .pageA4 {
    margin: 0;
    size: 21cm 29.7cm !important;
     
    width: 20cm;
    height: 14cm;
  }

  @page {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .fon2mm {
    font-size: 9pt;
    color: #000;
  }
  .fon1mm {
    font-size: 6pt;
    color: #000;
  }
  .fon1-5mm {
    font-size: 7pt;
    color: #000;
  }


  
}

@media screen {
  .pages {
    width: 10cm;
    height: 12cm;
    /* display: none; */
  }
  .pageA4 {
    size: A4;

    width: 20cm;
    height: 28cm;
  }
  .main {
    display: grid;
    grid-template-columns: 70% 30%;
    height: 100%;
  }

  .left {
    background-color: #cbcbcb;
  }

  .right {
    background-color: white;
    height: 100vh;
  }
}




table {
  width: 100%;
  border-collapse: collapse;
}


.scroll-container {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 100vh;
  background-color: white;
}

.btn-print {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: baseline;
}
.checkbox1 {
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  font-size: 1rem;
  text-align: end;
  color: black;
  width: 100%;
  

  display: flex;
  justify-content: center;
  align-items: baseline;
}
.btn-chip-group {
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  font-size: 1rem;
  text-align: right;
  color: black;
  width: 100%;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.checkbox {
  position: absolute;
  top: 15%;
  transform: translateY(-50%);
  font-size: 1rem;
  text-align: right;
  vertical-align: baseline;
  color: black;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-warning {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  flex-grow: 1;
  padding: 20px;
}
</style>